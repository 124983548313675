import vueFormGenerator from 'vue-form-generator';

export const model = {
	OspropId: 0,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'PtypeId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'section3520',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OspropNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OspropNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OspropNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OspropNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OspropNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OspropNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'OspropOrder',
				required: true,
				validator: vueFormGenerator.validators.required,
				styleClasses: 'quarter-width',
				i18n: {
					label: 'order2924',
				},
			},
			{
				type: 'switch',
				model: 'OspropInSearch',
				i18n: {
					label: 'displayedinsear',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'IsBasic',
				i18n: {
					label: 'itisbasicamongt',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'IsAllowedInAu',
				i18n: {
					label: 'displayedinaued',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
		],
	},
];
