<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		:showActionDelete="false"
		tooltipMsg="pimanualofdetai"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceCommon from '@/services/service/common.service';
import loading from '@/mixins/loading.mixin';

import { model, fields } from './object-simple-properties-form.data';
import { EntityTypes } from './services/entity-types.service';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'ObjectSimpleProperty',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailoftheopti');
			const name = this.model.OspropNameSk || '';
			const id = this.$t('id');
			const idNum = this.model.OspropId;
			return `${title} ${name} ${id} ${idNum}`;
		},
	},

	methods: {
		async loadResources() {
			this.updateSchemaFields({ PtypeId: { values: EntityTypes.forVfgDropdown(await EntityTypes.types) } });
		},
	},
};
</script>